import {
  INTIALIZE_SUCCESS,
  IS_FEEDBACK_REQUIRED,
  SAVE_LANGUAGES,
  SAVE_ZONES,
  ISFULLSCREEN
} from '../types/index';
const intialState = {
  isLoaded: false,
  languages: [],
  zones: [],
  isFeedbackRequired: false,
  isFullScreen: false
};

const intializeReducer = (state = intialState, action) => {
  switch (action.type) {
    case INTIALIZE_SUCCESS:
      return { ...state, isLoaded: action.payload };
    case SAVE_LANGUAGES:
      return { ...state, languages: [...action.payload] };
    case SAVE_ZONES:
      return { ...state, zones: [...action.payload] };
    case IS_FEEDBACK_REQUIRED:
      return { ...state, isFeedbackRequired: action.payload };
    case ISFULLSCREEN:
      return{ ...state, isFullScreen: action.payload }  
    default:
      return state;
  }
};

export default intializeReducer;
