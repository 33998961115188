import {
  SAVE_ZONES,
  INTIALIZE_SUCCESS,
  SAVE_LANGUAGES,
  IS_FEEDBACK_REQUIRED,
  ISFULLSCREEN
} from '../types';

export const intializeSuccess = (isSuccess) => {
  return {
    type: INTIALIZE_SUCCESS,
    payload: isSuccess,
  };
};

export const saveLanguages = (languages) => {
  return {
    type: SAVE_LANGUAGES,
    payload: languages,
  };
};

export const saveZones = (zones) => {
  return {
    type: SAVE_ZONES,
    payload: zones,
  };
};

export const isFeedbackRequired = (isRequired) => {
  return {
    type: IS_FEEDBACK_REQUIRED,
    payload: isRequired,
  };
};
export const isFullScreen=(fullScreen)=>{
  return{
    type:ISFULLSCREEN,
    payload:fullScreen,
  }

}