import { lazy } from 'react';
import { INTIAl_LOADING } from '../store/types';

const Registration = lazy(() => import('../components/registration'));
const IntialLoading = lazy(() => import('../components/intialloading'));
const IntroScene = lazy(() => import('../components/introscreen'));
const AsherIntro = lazy(() => import('../components/asherintro'));
const HelpScreen = lazy(() => import('../components/help'));
const ShoppingApp = lazy(() => import('../components/levelone'));
const AvatarSelection = lazy(() => import('../components/avatarselection'));
const HomeNumeracy = lazy(() => import('../components/morningwalk'));
const VehicleSelection = lazy(() => import('../components/vehicleselection'));
const FuelStation = lazy(() => import('../components/fuelstation'));
const CafeEnter = lazy(() => import('../components/cafe'));
const Cab = lazy(() => import('../components/carseating'));
const Bus = lazy(() => import('../components/busseating'));
const PictureGram = lazy(() => import('../components/picturegram'));
const ThreeImage = lazy(() => import('../components/paintings'));
const StoreIintro = lazy(() => import('../components/storeintro'));
const Bargain = lazy(() => import('../components/storebargain'));
const CafeConversations = lazy(() => import('../components/cafeconversations'));
const CafeTipPercentage = lazy(() => import('../components/cafetippercentage'));
const CafeTipQuestion = lazy(() => import('../components/cafetipquestion'));
const GroceryShop = lazy(() => import('../components/groceryshop'));
const FeedBack = lazy(() => import('../components/feedback'));
const ReturnHome = lazy(() => import('../components/finalscreen'));
const EndGame = lazy(() => import('../components/endgame'));

const getLevel = (levels, id) => levels.find((level) => level.levelType === id);
const getQuestion = (questions, id) =>
  questions.find((question) => question.questionSetId === id);
const gettipQuestion = (questions, id) =>
  questions.find((question) => question.questionId === id);
const getNarration = (messages, id) =>
  messages.find((message) => message.id === id);

const getImage = (images, id) => images.find((image) => image.id === id);

let walletQuestion,
  bikePetrol,
  busCharge,
  groceryQuestion,
  tipQuestion,
  carPetrol,
  cabCharge,
  introNarration,
  asherNarration,
  avatarSelectionNarration,
  vehicleSelectionNarration,
  vehicleBike,
  vehicleCar,
  vehicleTaxi,
  vehicleBus,
  cafeEnterNarration,
  avatarQuestion,
  shopKeeperReply,
  offerBargain,
  bargainQuote,
  packedText,
  cutOffPrice,
  bargainQuote2,
  finalPrice,
  checkPicturegram,
  paintingsAdviceRequest,
  paintingsAdvice,
  askBill,
  responseBill,
  questionPercentage5,
  questionPercentage10,
  questionPercentage15,
  groceryAvatar,
  tokensText,
  progressText,
  timeText,
  saleAnnouncement,
  tapToContinue,
  finalMessage,
  finalMessage2,
  finalButton,
  asherStoreEntry,
  labelBuy,
  labelLeave,
  labelQuote,
  labelYes,
  labelNo,
  labelAskFor,
  finalAsherText,
  bargainNarrations,
  percentageNumeracy,
  asherImage;

const setScreens = (
  currentScreen,
  setComponent,
  component,
  messages,
  levels,
  images,
  isFeedbackRequired
) => {
  const numeracyQuestions = getLevel(levels, 'numeracy');
  const levelOne = getLevel(levels, 'Level 1');
  const levelThree = getLevel(levels, 'Level 3');
  const levelFour = getLevel(levels, 'Level 4');

  if (messages) {
    introNarration = getNarration(messages, 'TEXT_BODYLESS_INTRO_1');
    asherNarration = getNarration(messages, 'TEXT_ASHER_INTRO_1');
    avatarSelectionNarration = getNarration(messages, 'ASHER_PLAYMOJI');
    vehicleSelectionNarration = getNarration(messages, 'ASHER_SELECT_TRAVEL');
    vehicleBike = getNarration(messages, 'LABEL_BIKE');
    vehicleCar = getNarration(messages, 'LABEL_CAR');
    vehicleTaxi = getNarration(messages, 'LABEL_TAXI');
    vehicleBus = getNarration(messages, 'LABEL_BUS');
    cafeEnterNarration = getNarration(messages, 'ASHER_CAFE');
    avatarQuestion = getNarration(messages, 'BARGAIN_AVATAR_1');
    shopKeeperReply = getNarration(messages, 'BARGAIN_SHOPKEEPER_1');
    offerBargain = getNarration(messages, 'BARGAIN_ASHER');
    bargainQuote = getNarration(messages, 'BARGAIN_AVATAR_2');
    packedText = getNarration(messages, 'BARGAIN_SHOPKEEPER_2');
    cutOffPrice = getNarration(messages, 'BARGAIN_SHOPKEEPER_3');
    bargainQuote2 = getNarration(messages, 'BARGAIN_AVATAR_3');
    finalPrice = getNarration(messages, 'BARGAIN_SHOPKEEPER_4');
    checkPicturegram = getNarration(messages, 'TEXT_PICTUREGRAM');
    paintingsAdviceRequest = getNarration(messages, 'AVATAR_RAHUL');
    paintingsAdvice = getNarration(messages, 'RAHUL_AVATAR');
    askBill = getNarration(messages, 'CONVERSATION_AVATAR_3');
    responseBill = getNarration(messages, 'CONVERSATION_NPC_4');
    groceryAvatar = getNarration(messages, 'TEXT_GROCERY');
    tokensText = getNarration(messages, 'TEXT_ASHER_TUTORIAL_1');
    progressText = getNarration(messages, 'TEXT_ASHER_TUTORIAL_2');
    timeText = getNarration(messages, 'TEXT_ASHER_TUTORIAL_3');
    saleAnnouncement = getNarration(messages, 'ASHER_SHOP_NOW');
    tapToContinue = getNarration(messages, 'LABEL_TAP_TO_CONTINUE');
    finalMessage = getNarration(messages, 'FINAL_SCREEN_MESSAGE');
    finalMessage2=getNarration(messages,'FEEDBACK_TITLE_TWO');
    finalButton = getNarration(messages, 'FINAL_SCREEN_BUTTON');
    finalAsherText = getNarration(messages, 'ASHER_SUBMIT');
    asherStoreEntry = getNarration(messages, 'ASHER_SHOPPING');
    labelBuy = getNarration(messages, 'LABEL_BUY');
    labelLeave = getNarration(messages, 'LABEL_LEAVE');
    labelQuote = getNarration(messages, 'LABEL_SLIDE_TO_QUOTE');
    labelYes = getNarration(messages, 'LABEL_YES');
    labelNo = getNarration(messages, 'LABEL_NO');
    labelAskFor = getNarration(messages, 'LABEL_ASK_FOR');

    bargainNarrations = {
      avatarQuestion,
      shopKeeperReply,
      offerBargain,
      bargainQuote,
      packedText,
      cutOffPrice,
      bargainQuote2,
      finalPrice,
    };
  }

  if (numeracyQuestions) {
    walletQuestion = getQuestion(numeracyQuestions.questions, 'wallet-options');
    bikePetrol = getQuestion(
      numeracyQuestions.questions,
      'scooter-petrol-fill-options'
    );
    busCharge = getQuestion(numeracyQuestions.questions, 'bus-charge');
    cabCharge = getQuestion(numeracyQuestions.questions, 'cab-charge');
    carPetrol = getQuestion(
      numeracyQuestions.questions,
      'car-petrol-fill-options'
    );
    groceryQuestion = getQuestion(
      numeracyQuestions.questions,
      'banana-options'
    );
    tipQuestion = getQuestion(
      numeracyQuestions.questions,
      'restaurant-tip-options'
    );

    questionPercentage5 = gettipQuestion(
      numeracyQuestions.questions,
      'restaurant-tip-options-5-1'
    );
    questionPercentage10 = gettipQuestion(
      numeracyQuestions.questions,
      'restaurant-tip-options-10-1'
    );

    questionPercentage15 = gettipQuestion(
      numeracyQuestions.questions,
      'restaurant-tip-options-15-1'
    );

    percentageNumeracy = [
      questionPercentage5,
      questionPercentage10,
      questionPercentage15,
    ];
  }

  //Images
  if (images) {
    const [imagesList] = images;
    asherImage = getImage(imagesList.content, 'ASHER');
  }

  switch (currentScreen) {
    case 'REGISTRATION':
      return setComponent(<Registration />);
    case INTIAl_LOADING:
      return setComponent(<IntialLoading />);
    case 'PRE_GAME_INTRO':
      return setComponent(
        <IntroScene
          audioClip={introNarration.audioClip}
          message={introNarration.message}
          tapToContinue={tapToContinue}
        />
      );
    case 'PRE_GAME_ASHER_INTRO':
      return setComponent(
        <AsherIntro
          asher={asherImage}
          narration={asherNarration}
          tapToContinue={tapToContinue}
        />
      );
    case 'PRE_GAME_HELP':
      return setComponent(
        <HelpScreen
          progressText={progressText}
          timeText={timeText}
          tokensText={tokensText}
          tapToContinue={tapToContinue}
        />
      );
    case 'PRE_GAME_AVATAR_SELECTION':
      return setComponent(
        <AvatarSelection
          asher={asherImage}
          narration={avatarSelectionNarration}
        />
      );
    case 'HOME_PHONE_SHOPPING':
      return setComponent(
        <ShoppingApp
          asher={asherImage}
          narration={saleAnnouncement}
          images={levelThree}
        />
      );
    case 'HOME_NUMERACY':
      return setComponent(
        <HomeNumeracy asher={asherImage} questions={walletQuestion} />
      );
    case 'HOME_CHOOSE_TRANSPORT':
      return setComponent(
        <VehicleSelection
          asher={asherImage}
          narration={vehicleSelectionNarration}
          vehicleBike={vehicleBike}
          vehicleCar={vehicleCar}
          vehicleTaxi={vehicleTaxi}
          vehicleBus={vehicleBus}
        />
      );
    case 'BIKE_FILL_FUEL_NUMERACY':
      return setComponent(
        <FuelStation bikePetrol={bikePetrol} carPetrol={carPetrol} />
      );
    case 'CAB_NUMERACY':
      return setComponent(<Cab questions={cabCharge} />);
    case 'BUS_NUMERACY':
      return setComponent(<Bus questions={busCharge} />);
    case 'DESTINATION_ENTER':
      return setComponent(
        <CafeEnter
          asher={asherImage}
          narration={cafeEnterNarration}
          tapToContinue={tapToContinue}
        />
      );
    case 'AVATAR_SOCIAL_MEADIA_CHECK':
      return setComponent(<PictureGram images={levelOne} />);
    case 'THREE_IMAGE_SELECTION':
      return setComponent(<ThreeImage images={levelFour} />);
    case 'DESTINATION_TO_STORE':
      return setComponent(
        <StoreIintro
          asher={asherImage}
          tapToContinue={tapToContinue}
          narration={asherStoreEntry}
        />
      );
    case 'STORE_AVATAR_QUERY':
      return setComponent(
        <Bargain
          narration={bargainNarrations}
          label={{
            labelBuy,
            labelLeave,
            labelQuote,
            labelYes,
            labelNo,
            labelAskFor,
          }}
          tapToContinue={tapToContinue}
        />
      );
    case 'AVATAR_REACH_CAFE':
      return setComponent(
        <CafeConversations
          asher={asherImage}
          checkPicturegram={checkPicturegram}
          tapToContinue={tapToContinue}
        />
      );
    case 'AVATAR_FRIEND_CONVERSATION_ONE':
      return setComponent(
        <CafeConversations
          paintingsAdvice={paintingsAdvice}
          paintingsAdviceRequest={paintingsAdviceRequest}
          tapToContinue={tapToContinue}
        />
      );
    case 'AVATAR_FRIEND_CONVERSATION_TWO':
      return setComponent(
        <CafeConversations
          // numeracyQuestions={percentageNumeracy}
          askBill={askBill}
          responseBill={responseBill}
          tapToContinue={tapToContinue}
        />
      );
    case 'CAFE_TIP_PERCENTAGE':
      return setComponent(<CafeTipPercentage numeracy={tipQuestion} />);
    case 'CAFE_TIP_PERCENTAGE_NUMERACY':
      return setComponent(<CafeTipQuestion numeracy={percentageNumeracy} />);

    case 'ENTER_GROCERY_SHOP':
      return setComponent(
        <GroceryShop
          groceryAvatar={groceryAvatar}
          numeracy={groceryQuestion}
          tapToContinue={tapToContinue}
        />
      );
    case 'RETURN_HOME':
      return setComponent(
        <ReturnHome
          asher={asherImage}
          narration={finalAsherText}
          tapToContinue={tapToContinue}
        />
      );

    case 'GAME_END':
      if (isFeedbackRequired) {
        return setComponent(<FeedBack buttonText={finalButton.message} finalMessage={finalMessage.message} finalMessage2={finalMessage2.message}/>);
      }
      return setComponent(
        <EndGame finalButton={finalButton} finalMessage={finalMessage} />
      );

    default:
      return component;
  }
};

export default setScreens;
