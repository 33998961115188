import React, { useRef, useEffect } from 'react';
import ConfettiExplosion from '@reonomy/react-confetti-explosion';
import './style.scss';
import {saveProgressBarPercentage} from '../../../store/screentransistion/actions';
import coinImage from '../../../assets/images/coins_display.png';
import roadImg from '../../../assets/images/progress_road.png';
import car from '../../../assets/images/car.png';
import timer from '../../../assets/images/time_display.png';
import { connect } from 'react-redux';
import useCounter from '../../../utils/useCounter';
import clapMusic from '../../../assets/audio/clapMusic.mp3'

const GameStatusBar = ({
  coins,
  time,
  currentsceneId,
  screenID,
  sceneDataList,
  progressBarPercentage,
  saveProgressBarPercentage,
}) => {
  const carRef = useRef(null);
  const roadRef = useRef(null);
  // useEffect(() => {
  //   const id = sceneDataList.find((scene) => scene.sceneID === screenID);
  //   if (currentsceneId !== 'PRE_GAME_HELP') {
  //     carRef.current.style.right = `${id.percentage - 10}%`;
  //   }
  //   // eslint-disable-next-line
  // }, [screenID]);

  useEffect(() => {
    const id = sceneDataList.find((scene) => scene.sceneID === screenID);
    if (currentsceneId !== 'PRE_GAME_HELP') {
      carRef.current.style.right = id?.percentage ? `${id.percentage - 10}%` : `${progressBarPercentage -10}%`;
    }
    if(id?.percentage){
          saveProgressBarPercentage(id.percentage)
        }
    // eslint-disable-next-line
  }, [screenID]);

  
  const [minutes, sec] = useCounter();
  const [isExploding, setIsExploding] = React.useState(false);

  useEffect(() => {
  if (progressBarPercentage == 100){
    setIsExploding(true)
  }
  else{
    setIsExploding(false)
  }
}, [progressBarPercentage]);

  return (
    <>
    {isExploding && <div className='explosion1'><ConfettiExplosion/></div>}
    {isExploding && <audio controls autoPlay className='fire_audio'>
        <source src={clapMusic}></source>
        </audio>}
      <div className="staus__bar__container">
        <div className="coins_container">
          <img className="coins__display__img" src={coinImage} alt="coins" />
          <div className="coins">{coins}</div>
        </div>
        <div className="progress__bar__container">
          <img
            ref={roadRef}
            src={roadImg}
            alt="road for progess"
            className="road"
          />
          <img ref={carRef} src={car} alt="car on road" className="car" />
        </div>
        <div className="timer__container">
          <img src={timer} alt="game timer" className="timer" />
          <div className="time">
            {currentsceneId !== 'PRE_GAME_HELP' ? (
              <>
                {`0${minutes}`}:{sec < 10 ? `0${sec}` : sec}
              </>
            ) : (
              <>4:00</>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    coins: state.trackingUser.user.coins,
    currentsceneId: state.screenChange.screenID,
    sceneDataList: state.shuffleData.sceneDataList,
    screenID: state.screenChange.screenID,
    progressBarPercentage: state.screenChange.progressBarPercentage,
  };
};
const mapDispatchToprops = dispatch => {
  return{
    saveProgressBarPercentage: (percentage) => dispatch(saveProgressBarPercentage(percentage))
  }
}
export default connect(mapStateToProps,mapDispatchToprops)(GameStatusBar);
