import axios from 'axios';

export let headers = {
  'Tenant-Access-Token': 'ZWYyYzYzYTkzYTA5NzlmMTgyZDk2YWEwODE3MjAwYzY=',
  'Tenant-Access-Key': '7kmLbfGNUlwx0Q',
};

export const api = axios.create({
  baseURL: 'https://tiltlabs-dev.confirmu.com',
  headers: {
    'Tenant-Access-Token': 'ZWYyYzYzYTkzYTA5NzlmMTgyZDk2YWEwODE3MjAwYzY=',
    'Tenant-Access-Key': '7kmLbfGNUlwx0Q',
  },
});

export const BASE_URL = 'https://tiltlabs-dev.confirmu.com';
