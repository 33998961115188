import {
  AVATAR_GENDER,
  CURRENT_SCREEN_ID,
  INTIAl_LOADING,
  IS_WINDOW_OPEN,
  SET_INDEX,
  START_STOP_WATCH,
  STOP_STOP_WATCH,
  TIP_QUESTION,
  TOGGLE_MUSIC,
  TOGGLE_VOICE_OVER,
  SAVE_PROGRESS_BAR_PERCENTAGE,
} from '../types';

export const nextScreen = (id) => {
  return {
    type: CURRENT_SCREEN_ID,
    payload: id,
  };
};

export const setIndex = (index) => {
  return {
    type: SET_INDEX,
    payload: index,
  };
};

export const intialLoading = () => {
  return {
    type: INTIAl_LOADING,
    payload: INTIAl_LOADING,
  };
};

export const startStopWatch = (start) => {
  return {
    type: START_STOP_WATCH,
    payload: start,
  };
};

export const stopStopWatch = (stop) => {
  return {
    type: STOP_STOP_WATCH,
    payload: stop,
  };
};

export const setAvatarGender = (gender) => {
  return {
    type: AVATAR_GENDER,
    payload: gender,
  };
};

export const toggleMusic = (toggle) => {
  return {
    type: TOGGLE_MUSIC,
    payload: toggle,
  };
};

export const toggleVoiceOver = (toggle) => {
  return {
    type: TOGGLE_VOICE_OVER,
    payload: toggle,
  };
};

export const tipQuestion = (question) => {
  return {
    type: TIP_QUESTION,
    payload: question,
  };
};

export const isWindowOpen = (state) => {
  return {
    type: IS_WINDOW_OPEN,
    payload: state,
  };
};
export const saveProgressBarPercentage = (percentage) => {
  return {
    type: SAVE_PROGRESS_BAR_PERCENTAGE,
    payload: percentage
  }
}
